import { StyledButton } from 'components/Inputs/Button'
import { useEffect, useState } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { logEvent } from 'ga'

import styles from 'public/static/scss/LandingPage.module.scss'
import FirstFoldSticky from './FirstFoldSticky'
import { IMG_URLS_DESKTOP, IMG_URLS_MOBILE } from '../constant'
import withWindowDimensions from 'components/common/withWindowDimensions'
import { trackClick } from 'scripts/segment'
import { pageName } from 'utils/events-utils'
import { AuthToken } from 'lib/auth_token'
import { counsellingStageRank } from 'DataMapper/Profile/StageMapper'
import { navRoutes } from 'page_routes'

type Props = { isMobileSized?: boolean; windowWidth?: number }

const LandingHero = ({ isMobileSized, windowWidth }: Props) => {
  const counsellingStage = useSelector(
    (state: any) => state.spotCounselling.stage,
  )
  const authValue = useSelector((state: any) => state?.auth?.auth?.token)
  const authToken = new AuthToken(authValue)
  const isLoggedIn = authToken && authToken.isValid
  const router = useRouter()
  const [heroCtaText, setHeroCtaText] = useState('Start your journey')

  const handleCtaButton = () => {
    trackClick(pageName.LeapScholarHomePage, {
      widgetName: 'Hero Section',
      widgetFormat: 'Banner',
      contentName: 'Start your Journey',
      contentFormat: 'Button',
    })

    if (isLoggedIn) {
      if (counsellingStageRank[counsellingStage as string] <= 2) {
        logEvent('Get Expert Counselling', router.asPath, 'Banner')
        router.push(`/planner`)
      } else {
        logEvent('Get Access', router.asPath, 'Banner')
        router.push(navRoutes.POSTLOGIN_COUNSELLING)
      }
    } else {
      router.push('/planner')
    }
  }

  // TODO:: need to abstract this logic to some hoc or hook
  useEffect(() => {
    if (isLoggedIn) {
      if (counsellingStageRank[counsellingStage as string] <= 2) {
        setHeroCtaText('Start your journey')
      } else {
        setHeroCtaText('Get Access')
      }
    } else {
      setHeroCtaText('Start your journey')
    }
  }, [isLoggedIn, counsellingStage])

  return (
    <div className="w-full">
      <div
        className={`absolute h-[700px] md:max-h-[700px] max-h-[1000px] w-full z-10 max-w-[100vw] ${styles.revampHero}`}
        id="stage"
      >
        <div
          className={`w-full h-[700px] md:max-h-[700px] max-h-[1000px] image`}
          id={'image'}
        >
          <Image
            src={
              isMobileSized ? IMG_URLS_MOBILE[0].url : IMG_URLS_DESKTOP[0].url
            }
            alt={
              isMobileSized ? IMG_URLS_MOBILE[0].alt : IMG_URLS_DESKTOP[0].alt
            }
            priority={true}
            width={windowWidth}
            height={isMobileSized ? '567px' : '700px'}
            placeholder="blur"
            blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjcLH7/x8ABMwCgfmZ8pQAAAAASUVORK5CYII="
            layout={'fill'}
            objectFit="cover"
          />
        </div>
      </div>
      <div className=" z-20 md:w-8/10 w-full h-[700px] absolute">
        <div className="md:ml-[8rem] md:w-1/2 mx-auto md:mr-0 w-full">
          <h1 className="md:text-font56 font-bold text-font32 text-white drop-shadow-md md:mt-[80px] mt-[20px] md:text-left text-center">
            Powering your global education dream
          </h1>
          <StyledButton
            ctaText={heroCtaText}
            onClick={handleCtaButton}
            className="relative z-1000 font-bold md:text-font16 text-white py-4 w-1/2 mt-8 hidden md:block"
          />
          <div className={'-mt-8 left-[40%] relative hidden md:block'}>
            <Image
              src={'/assets/images/LSRevamp/aeroplane-landing.svg'}
              width={122}
              height={88}
            />
          </div>
          <div className="flex h-full items-center justify-center md:justify-start md:mt-6 mt-6 max-w-full w-full">
            <div className="flex flex-col">
              <p className="md:text-font32 text-font20 font-bold text-white text-center">
                1,00,000+
              </p>
              <p className="md:text-font16 text-font14 font-normal text-white text-center">
                Success Stories
              </p>
            </div>
            <div className=" border-[#C1C2C7] h-[50px] border mx-4" />
            <div className="flex flex-col">
              <p className="md:text-font32 text-font20 font-bold text-white text-center">
                10,000+
              </p>
              <p className="md:text-font16 text-font14 font-normal text-white text-center">
                Preferred Courses
              </p>
            </div>
          </div>
          <div className="absolute bottom-[130px] w-9/10 right-0 left-0 mx-auto md:hidden">
            <StyledButton
              ctaText={heroCtaText}
              onClick={handleCtaButton}
              className="font-bold md:text-font16 text-white py-4 w-full mt-8"
            />
          </div>
        </div>
      </div>
      <FirstFoldSticky isMobileSized={isMobileSized as boolean} />
    </div>
  )
}

export default withWindowDimensions(LandingHero)
