import { useState } from 'react'

const LongFormContent = () => {
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => setOpen((prev) => !prev)

  return (
    <>
      <div className="section bg-[#F7F7F7] rounded-[8px] py-4 px-[48px] my-[32px] max-w-[1200px] m-auto text-center flex flex-col gap-6">
        <h3 className="text-xl leading-[28px] font-bold">
          LeapScholar : Your Trusted Guide For Study Abroad Success
        </h3>

        <p>
          So, you have decided to study abroad. As you embark on this
          life-changing journey, LeapScholar will be your trusted advisor
          guiding you towards your destination.
          <br />
          Studying in a foreign country is appealing, promising and a wonderful
          experience. Nevertheless, securing that coveted spot in your{' '}
          <a href="/university?utm_source=homepage&utm_medium=footer+content">
            dream university
          </a>{' '}
          demands tenacity and careful planning. LeapScholar provides end-to-end
          services and an extensive suite of innovative products on a single
          platform to meet all your study abroad requirements.
          <br />
          We are committed to our vision of democratising international
          education and ready to assist in your quest for knowledge and growth
        </p>

        {isOpen ? (
          <>
            <div className="flex flex-col gap-3">
              <h3 className="text-[18px] leading-[28px] font-bold">
                Why Study Abroad?
              </h3>
              <p>
                Studying abroad is an opportunity of a lifetime and empowers you
                to do great things. Here are a few crucial reasons you should
                study abroad:
              </p>

              <ul className="flex flex-col gap-3">
                <li>
                  <strong>Superior quality of education and lifestyle</strong>
                  <br />
                  Education systems in countries such as the{' '}
                  <a href="/usa?utm_source=homepage&utm_medium=footer+content">
                    U.S.
                  </a>{' '}
                  ,{' '}
                  <a href="/uk?utm_source=homepage&utm_medium=footer+content">
                    U.K.
                  </a>
                  and{' '}
                  <a href="/canada?utm_source=homepage&utm_medium=footer+content">
                    Canada
                  </a>{' '}
                  are renowned for their high standards and top-class
                  facilities. These countries also have superior living
                  standards, including access to world-class healthcare
                  facilities.
                </li>
                <li>
                  <strong>Global career opportunities</strong>
                  <br />
                  Skilled talent is in high demand worldwide. With more and more
                  economies opening up to an increased number of immigrants, job
                  opportunities overseas are on the rise. Studying abroad
                  enables students to pursue international careers
                </li>
                <li>
                  <strong>Better salaries and perks</strong>
                  <br />
                  Working abroad after completing studies is something every
                  international student looks forward to. Not only do you earn
                  more working overseas, but earning in dollars or euros gives
                  you an edge in today’s world.
                </li>
                <li>
                  <strong>International networking</strong>
                  <br />
                  Studying abroad helps you befriend people from all over the
                  world and build an international network of peers. This will
                  help you immensely in the long run.
                </li>
                <li>
                  <strong>Get your permanent residency</strong>
                  <br />
                  Studying overseas boosts your chances of getting that coveted
                  permanent residency and building a bright future for yourself
                  and your family.
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-3">
              <h3 className="text-[18px] leading-[28px] font-bold">
                Consult India’s Best Study Abroad Experts On LeapScholar{' '}
              </h3>

              <p>
                Many students dream of studying abroad and having international
                careers. This dream can be easily realised with the help of a{' '}
                <a href="/university?utm_source=homepage&utm_medium=footer+content">
                  college
                </a>{' '}
                or{' '}
                <a href="/university?utm_source=homepage&utm_medium=footer+content">
                  university
                </a>{' '}
                degree from countries such as the U.S., UK or Canada. Each year,
                millions of students leave their home countries to study
                overseas and build brighter futures.
                <br />
                At every step, it is essential to be guided by an{' '}
                <a href="/counsellors?utm_source=homepage&utm_medium=footer+content">
                  expert
                </a>{' '}
                so that the student makes the right choices. This is where
                LeapScholar, one of the world’s largest study abroad platforms,
                steps in to help aspirants deftly cruise through an ocean of
                options and processes.
                <br />
                Over two million students are now part of LeapScholar's thriving
                online community. This unique international network is
                accessible to any student who wants to participate through the
                platform.
                <br />
                Over 3,000+{' '}
                <a href="/counsellors?utm_source=homepage&utm_medium=footer+content">
                  counsellors
                </a>{' '}
                on the platform guide students through choosing their{' '}
                <a href="/courses?utm_source=homepage&utm_medium=footer+content">
                  {' '}
                  courses
                </a>
                ,{' '}
                <a href="/university?utm_source=homepage&utm_medium=footer+content">
                  colleges
                </a>
                , and{' '}
                <a href="/university?utm_source=homepage&utm_medium=footer+content">
                  universities
                </a>
                . Their expertise helps students prepare stellar Statements of
                Purpose (or SOPs) and source the right Letters of Recommendation
                (LORs). These are critical components of the application process
                for foreign academic institutions.
                <br />
                From counselling for choosing{' '}
                <a href="/courses?utm_source=homepage&utm_medium=footer+content">
                  {' '}
                  courses
                </a>{' '}
                and{' '}
                <a href="/university?utm_source=homepage&utm_medium=footer+content">
                  colleges
                </a>
                , IELTS and SAT prep, or writing the perfect statement of
                purpose for your application, LeapScholar has a product or a
                service to guide you through the study abroad journey.
              </p>
            </div>

            <div className="flex flex-col gap-3">
              <h3 className="text-[18px] leading-[28px] font-bold">
                Ace Your English Proficiency Test - Download IELTS Prep by
                LeapScholar App!{' '}
              </h3>

              <div className="flex flex-col gap-3">
                <div>
                  <h3 className="text-[18px] leading-[28px] font-bold">
                    <a href="/exams/ielts?utm_source=homepage&utm_medium=footer+content">
                      IELTS
                    </a>
                  </h3>

                  <p>
                    IELTS, or International English Language Testing System, is
                    a proficiency test designed to understand one's reading,
                    writing, speaking and listening abilities in English. Those
                    looking to study or work abroad in an English-speaking
                    country, such as the U.S., UK, Canada or Australia, must
                    provide an English-language proficiency test score during
                    college admissions or when applying for a permanent
                    residency. The IELTS Academic test is for students looking
                    to study abroad, while the IELTS General Training module is
                    for those applying for jobs or permanent residency in an
                    English-speaking country.
                    <br />
                    IELTS Prep by LeapScholar is a pioneering test prep service
                    for acing the English-language test accepted by almost all
                    foreign academic institutions. The app has seen over 2+
                    million downloads and is among the most favoured globally in
                    the IELTS test prep space. The app allows students to access
                    free masterclasses from reputed and certified IELTS
                    trainers. There are also specialised IELTS training courses
                    on offer at affordable prices. Trainers cover all topics
                    from basics to advanced in the IELTS Plus course. Extra
                    classes are available for those who need to improve their
                    English grammar.
                    <br />
                    Students also appear in mock tests, which are evaluated by
                    experts who advise precisely where you need to improve your
                    performance. The unique feature of this app allows you to
                    revisit the recorded training session at any time, and you
                    also get lifetime access to them. A standout feature of the
                    IELTS Prep by LeapScholar app is the 'Speaking Room', where
                    students can practise English speaking with fellow
                    aspirants.
                  </p>
                </div>
                <div>
                  <h3 className="text-[18px] leading-[28px] font-bold">
                    Duolingo English Test
                  </h3>

                  <p>
                    The Duolingo English Test is a language proficiency exam,
                    and the scores are accepted by over 3,500 institutions
                    worldwide. This exam can be taken from the comfort of your
                    home using a computer, webcam, microphone and speakers.
                    There is no need to travel to a test centre. Moreover, you
                    can send your scores for free to as many institutions as you
                    want. The test is adaptive, meaning the difficulty level
                    increases with each correct answer. Duolingo assesses a test
                    taker's communication abilities on four parameters: reading,
                    writing, speaking and listening. Read more about the{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://leapscholar.com/blog/duolingo-ielts-score-conversion/"
                    >
                      Duolingo English Test
                    </a>
                    .
                  </p>
                </div>

                <h3 className="text-[18px] leading-[28px] font-bold">
                  <a href="/exams/sat?utm_source=homepage&utm_medium=footer+content">
                    SAT
                  </a>
                  ,{' '}
                  <a href="/exams/gre?utm_source=homepage&utm_medium=footer+content">
                    GRE
                  </a>
                  ,{' '}
                  <a href="/exams/gmat?utm_source=homepage&utm_medium=footer+content">
                    GMAT
                  </a>
                  - Which Exams Should You Write For Studying Abroad?
                </h3>

                <div>
                  <h3 className="text-[18px] leading-[28px] font-bold">
                    <a href="/exams/sat?utm_source=homepage&utm_medium=footer+content">
                      SAT
                    </a>
                  </h3>

                  <p>
                    Students must furnish SAT scores to seek admission to
                    undergraduate schools in the U.S. and many other countries.
                    Higher SAT scores benefit students as many universities
                    offer up to 100% scholarships to those with higher scores.
                    Over 4,000 universities in more than 80+ countries accept
                    SAT scores for undergraduate admissions.
                    <br />
                    LeapScholar’s SAT prep service is conducted by experienced
                    faculty. The full-fledged online SAT prep study portal has
                    thousands of practice questions. Trainers provide an
                    in-depth analysis of the strengths and weaknesses of
                    students who want to appear for their SAT exam.
                    LeapScholar's Princeton Review-certified SAT trainers come
                    with 10+ years of experience. Book your free{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://satprep.leapscholar.com/?utm_source=homepage&utm_medium=footer+content"
                    >
                      SAT Masterclass
                    </a>{' '}
                    session today.
                  </p>
                </div>

                <div>
                  <h3 className="text-[18px] leading-[28px] font-bold">
                    <a href="/exams/gre?utm_source=homepage&utm_medium=footer+content">
                      GRE
                    </a>
                  </h3>

                  <p>
                    The Graduate Record Examinations, or GRE, is a standardised
                    test created and administered by the Education Testing
                    Service, or ETS, that measures the overall academic
                    readiness of students for graduate school. It had three
                    sections: analytical writing, verbal reasoning and
                    quantitative reasoning. Some grad schools require students
                    to take the GRE Subject Test and the GRE General Test, which
                    measures one’s knowledge and skill levels in Chemistry,
                    Mathematics, Physics and Psychology. Read more about how to{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://leapscholar.com/blog/apply-for-gre-exam-now-easy-steps-to-register/"
                    >
                      register
                    </a>{' '}
                    for the exam.
                  </p>
                </div>

                <div>
                  <h3 className="text-[18px] leading-[28px] font-bold">
                    <a href="/exams/gmat?utm_source=homepage&utm_medium=footer+content">
                      GMAT
                    </a>
                  </h3>

                  <p>
                    The Graduate Management Admission Test, or GMAT, exam score
                    is used to evaluate candidates seeking admission to graduate
                    business and management (MBA) programs. A good GMAT score
                    also helps in getting financial aid and scholarships. The
                    validity of the score is for five years. It tests candidates
                    in analytical writing, integrated, verbal, and quantitative
                    reasoning. The Graduate Management Admissions Council has
                    developed and administers the test. Check the{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://leapscholar.com/blog/save-the-dates-gmat-exam-date/"
                    >
                      available dates
                    </a>{' '}
                    for the test.{' '}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <h3 className="text-[18px] leading-[28px] font-bold">
                Get Your Master’s Degree From U.S. With Leap Advantage
              </h3>

              <p>
                The Leap Advantage program allows enrolling in a U.S. university
                and graduating with a master’s degree. Students will complete
                part of the program in India and the rest on the university
                campus in the U.S. After graduation, you will be eligible for a
                work visa in the U.S.
              </p>

              <h4 className="text-base leading-[28px] font-bold">
                Join the Master of Computer Information Systems (MCIS) Hybrid
                Program at{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://advantage.leapscholar.com/colorado-state-university-mcis?utm_source=homepage&utm_medium=footer+content"
                >
                  Colorado State University, U.S
                </a>
                .
              </h4>

              <ul>
                <li>Save up to 33% on education and living expenses</li>
                <li>
                  Complete with a 3-year work visa after course completion
                </li>
                <li>
                  Join the course remotely in the first semester while you work
                  in India
                </li>
                <li>GRE/GMAT waivers are available</li>
              </ul>

              <i>
                *Credit transferability is subject to review and approval by the
                receiving institution
              </i>
              <p>
                To know more, book a{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://advantage.leapscholar.com/?utm_source=homepage&utm_medium=footer+content"
                >
                  free session
                </a>{' '}
                with our{' '}
                <a href="/counsellors?utm_source=homepage&utm_medium=footer+content">
                  {' '}
                  counsellor
                </a>
                .
              </p>
              <p>
                LeapScholar’s experienced study abroad consultants can help you
                with all of the above and any other aspect of studying abroad.
                Our trained overseas education consultants will guide you
                through each step of this incredible, life-changing voyage that
                you are about to embark on! Speak to our study abroad
                consultants and resolve all of your queries. Book a free session
                now on the LeapScholar website.
              </p>
            </div>

            <div className="flex flex-col gap-3">
              <h3 className="text-[18px] leading-[28px] font-bold">
                Study Abroad: Frequently Asked Questions
              </h3>

              <div className="flex flex-col gap-2">
                <div>
                  <h4 className="text-base leading-[28px] font-bold">
                    What are the benefits of studying abroad?
                  </h4>
                  <p>
                    Studying abroad is a unique experience with multiple
                    benefits, such as access to superior education systems, top
                    global faculty and the opportunity to have an international
                    career. Going overseas for higher education is a golden
                    ticket to experiencing the world and new cultures, which
                    helps build your social and professional profile.
                  </p>
                </div>

                <div>
                  <h4 className="text-base leading-[28px] font-bold">
                    Which countries are the best study-abroad destinations?
                  </h4>
                  <p>
                    The{' '}
                    <a href="/usa?utm_source=homepage&utm_medium=footer+content">
                      U.S.
                    </a>
                    ,{' '}
                    <a href="/uk?utm_source=homepage&utm_medium=footer+content">
                      UK
                    </a>
                    ,{' '}
                    <a href="/canada?utm_source=homepage&utm_medium=footer+content">
                      Canada
                    </a>
                    , and{' '}
                    <a href="/australia?utm_source=homepage&utm_medium=footer+content">
                      Australia
                    </a>{' '}
                    are the most popular destinations for students who wish to
                    study abroad.{' '}
                    <a href="/ireland?utm_source=homepage&utm_medium=footer+content">
                      Ireland
                    </a>{' '}
                    is one of the emerging countries for international students.
                  </p>
                </div>

                <div>
                  <h4 className="text-base leading-[28px] font-bold">
                    What are the requirements for study abroad courses?
                  </h4>
                  <p>
                    Each study abroad program has different academic and other
                    requirements. The basic requirement for undergraduate
                    studies abroad is a good higher secondary (10+2) mark sheet.
                    For postgraduation, you need to have a good undergraduate
                    degree. Most foreign{' '}
                    <a href="/university?utm_source=homepage&utm_medium=footer+content">
                      colleges
                    </a>{' '}
                    and{' '}
                    <a href="/university?utm_source=homepage&utm_medium=footer+content">
                      universities
                    </a>{' '}
                    also require an English proficiency test score, such as{' '}
                    <a href="/exams/ielts?utm_source=homepage&utm_medium=footer+content">
                      IELTS
                    </a>
                    . There may be other requirements for specific{' '}
                    <a href="/courses?utm_source=homepage&utm_medium=footer+content">
                      courses
                    </a>
                    .
                  </p>
                </div>
                <div>
                  <h4 className="text-base leading-[28px] font-bold">
                    Which exams are required to study abroad?
                  </h4>
                  <p>
                    Aspiring students must write English-language proficiency
                    tests such as{' '}
                    <a href="/exams/ielts?utm_source=homepage&utm_medium=footer+content">
                      IELTS
                    </a>{' '}
                    and{' '}
                    <a href="/exams/toefl?utm_source=homepage&utm_medium=footer+content">
                      TOEFL
                    </a>{' '}
                    to study abroad. For specific courses, students will need to
                    write exams such as{' '}
                    <a href="/exams/gre?utm_source=homepage&utm_medium=footer+content">
                      GRE
                    </a>
                    ,{' '}
                    <a href="/exams/gmat?utm_source=homepage&utm_medium=footer+content">
                      GMAT
                    </a>
                    ,{' '}
                    <a href="/exams/sat?utm_source=homepage&utm_medium=footer+content">
                      SAT
                    </a>{' '}
                    or any other test specified by the{' '}
                    <a href="/university?utm_source=homepage&utm_medium=footer+content">
                      college
                    </a>{' '}
                    or{' '}
                    <a href="/university?utm_source=homepage&utm_medium=footer+content">
                      university
                    </a>
                    .
                  </p>
                </div>
                <div>
                  <h4 className="text-base leading-[28px] font-bold">
                    What is the cost of studying abroad?
                  </h4>
                  <p>
                    Studying abroad can be cheap if you plan your funding well
                    in advance. There are many{' '}
                    <a href="/scholarship-finder?utm_source=homepage&utm_medium=footer+content">
                      scholarships
                    </a>{' '}
                    and grants available for Indian students abroad. Platforms
                    like{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://leapfinance.com/?utm_source=homepage&utm_medium=footer+content"
                    >
                      LeapFinance
                    </a>{' '}
                    offer collateral-free student financing that covers your
                    tuition and living costs.
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : null}

        <button onClick={handleOpen}>
          <div className="flex items-center text-base justify-center font-semibold text-primary">
            Read {isOpen ? 'Less' : 'More'}{' '}
            <img
              src="/assets/icons/LSRevamp/arrow-icon.svg"
              alt="arrow"
              className={isOpen ? '-rotate-180 transition-all' : ''}
            />
          </div>
        </button>
      </div>

      <style jsx>{`
        .section :global(ul li) {
          list-style: disc;
          list-style-position: inside;
        }

        .section a {
          color: #443eff;
        }
      `}</style>
    </>
  )
}

export default LongFormContent
