import Image from "next/image";
import { imageLogoData } from "../constant";
import { LogoLandingInterface } from "../LSRevampInterfaces";

type Props = { isMobileSized: boolean };

const FirstFoldSticky = ({ isMobileSized }: Props) => {
  return (
    <div
      className={`mx-auto md:max-w-[1200px] md:w-6/10 w-9/10 bg-gradient-to-b from-white to-[#F0E9F8] absolute flex flex-col z-100 md:top-[650px] top-[620px] rounded-10 py-8 left-0 right-0`}
    >
      <div className="md:w-8/10 w-9/10 mx-auto">
        <div className="flex">
          <div className={`bg-[#E2F9DF] px-2 py-1 w-fit rounded-3`}>
            <p className={`text-[#3C9860] text-font14 font-normal`}>
              Press Release
            </p>
          </div>
          <div className={`bg-[#DBE6FF] px-2 py-1 w-fit ml-2 rounded-3`}>
            <p className={`text-[#5940F0] text-font14 font-normal`}>Ed-Tech</p>
          </div>
        </div>
        <p className="md:text-font32 font-bold text-font24 text-black mx-auto mt-2">
          Leap raises 500 Cr to help Indian students to study abroad
        </p>
        <div className="flex w-full justify-between items-center mt-4">
          {imageLogoData.map((imgData: LogoLandingInterface, index: number) => (
            <Image
              objectFit="cover"
              key={index}
              alt="investor-logo"
              src={imgData.url}
              height={isMobileSized ? 30 : 58}
              width={isMobileSized ? 65 : 148}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FirstFoldSticky;
