import withWindowDimensions from 'components/common/withWindowDimensions'
import { counsellingStageRank } from 'DataMapper/Profile/StageMapper'
import { logEvent } from 'ga'
import { AuthToken } from 'lib/auth_token'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { navRoutes } from 'page_routes'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { trackClick } from 'scripts/segment'
import { pageName } from 'utils/events-utils'
import { LogoLandingInterface } from 'components/LSRevamp/LSRevampInterfaces'
import { imageLogoData } from 'components/LSRevamp/constant'

const LandingHero = () => {
  const counsellingStage = useSelector(
    (state: any) => state.spotCounselling.stage,
  )
  const authValue = useSelector((state: any) => state?.auth?.auth?.token)
  const authToken = new AuthToken(authValue)
  const isLoggedIn = authToken && authToken.isValid
  const router = useRouter()
  const [heroCtaText, setHeroCtaText] = useState('Book a free session')

  const handleCtaButton = () => {
    trackClick(pageName.LeapScholarHomePage, {
      widgetName: 'Hero Section',
      widgetFormat: 'Banner',
      contentName: 'Book a free session',
      contentFormat: 'Button',
    })

    if (isLoggedIn) {
      if (counsellingStageRank[counsellingStage as string] <= 2) {
        logEvent('Get Expert Counselling', router.asPath, 'Banner')

        router.push(`/planner`)
      } else {
        logEvent('Get Access', router.asPath, 'Banner')
        router.push(navRoutes.POSTLOGIN_COUNSELLING)
      }
    } else {
      router.push('/planner')
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      if (counsellingStageRank[counsellingStage as string] <= 2) {
        setHeroCtaText('Book a free session')
      } else {
        setHeroCtaText('Get Access')
      }
    } else {
      setHeroCtaText('Book a free session')
    }
  }, [isLoggedIn, counsellingStage])

  return (
    <>
      <div
        className="w-full"
        style={{
          backgroundImage: `url("https://ik.imagekit.io/onsnhxjshmp/LeapScholar/landing-background_ExsLaCLOL.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660810663105")`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="mx-auto w-full text-center">
          <div className="block md:hidden">
            <img
              src="https://ik.imagekit.io/onsnhxjshmp/LeapScholar/landing-img-web_oddZIx-4u.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660806483510"
              alt="img"
              className="w-full max-h-[400px]"
            />
          </div>

          <p className="text-white text-[16px] md:text-[20px] pt-[30px] mx-4 md:mx-0">
            The best guidance for your
          </p>
          <h1 className="font-bold text-[32px] md:text-[56px] text-white mx-4 md:mx-0">
            STUDY ABROAD DREAM
          </h1>
          <h3 className="text-white text-[16px] md:text-[20px] mx-4 md:mx-0">
            Start your journey with the best study abroad experts in India
          </h3>
          <div className="flex justify-center items-center mt-[34px] mb-[48px]">
            <button
              style={{
                background:
                  'linear-gradient(96.48deg, #443EFF 4.9%, #703EFF 101.18%)',
                boxShadow: '0px 1px 5px #9C27B01F',
                borderRadius: '3px',
                opacity: 1,
              }}
              className="flex justify-center items-center py-3 px-4 md:py-2 text-white font-bold text-sm md:text-base text-center rounded cursor-pointer w-full md:w-auto mx-4 md:mx-0"
              onClick={handleCtaButton}
            >
              <p>{heroCtaText}</p>{' '}
              <img
                className="w-[20px] md:w-[30px] ml-2"
                src="/assets/icons/right-white-icon.svg"
                alt="icon"
              />
            </button>
          </div>

          <div className="md:block hidden h-[662px]">
            <Image
              className="rounded-lg"
              src="https://ik.imagekit.io/onsnhxjshmp/LeapScholar/landing-img-web_oddZIx-4u.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1660806483510"
              width={662}
              height={508}
            />
          </div>
        </div>
        <div className="relative h-[40px]">
          <img
            src="/assets/images/landing_page/hero_last_left_web.svg"
            alt="icon"
            className="absolute z-20 left-0 w-100 md:w-[200px] bottom-[-48px] md:bottom-[-95px] "
          />
          <img
            src="/assets/images/landing_page/hero_last_right_web.svg"
            alt="icon"
            className="absolute z-20 right-0 w-100 md:w-[200px] bottom-[-22px] md:bottom-[-45px]"
          />
        </div>
      </div>
      <div className="hidden md:block mx-[200px] mt-[46px]">
        <p className="text-center text-gray-950 text-[22px] mb-[20px]">
          Trusted by Global Investors
        </p>
        <div className="flex w-full justify-evenly mx-auto items-center mt-4 max-w-[960px]">
          {imageLogoData.map((imgData: LogoLandingInterface, index: number) => (
            <Image
              objectFit="cover"
              key={index}
              alt="investor-logo"
              src={imgData.url}
              height={50}
              width={100}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default withWindowDimensions(LandingHero)
