import { useRouter } from 'next/router'
import { FunctionComponent, useEffect, useState } from 'react'
import axios from 'axios'
import withWindowDimensions from 'components/common/withWindowDimensions'
import { counsellingStageRank } from 'DataMapper/Profile/StageMapper'
import { navRoutes } from 'page_routes'
import { StyledButton } from 'components/Inputs/Button'
import { trackClick } from 'scripts/segment'
import { getTokenCookie } from 'lib/auth-cookies-new'
import { AuthToken } from 'lib/auth_token'

type Props = {
  isMobileSized?: boolean
  addedPageName?: string
  showOnlyPaymentNudge?:any
  paymentPageUrl?:string;
}

const OnPageTofuNudgeButton: FunctionComponent<Props> = ({
  isMobileSized,
  addedPageName,
  showOnlyPaymentNudge,
  paymentPageUrl,
}) => {
  const token = getTokenCookie()
  const auth = new AuthToken(token)
  const router = useRouter()
  const isLoggedIn: any = auth && auth.isValid
  const [buttonFlow, setButtonFlow] = useState<string | null>(null)
  useEffect(() => {
    if (isLoggedIn) {
      fetchUserData()
    }
  }, [])

  const fetchUserData = async () => {
    try {
      const res = await axios.get(
        `/api/scholarRoute?path=counselling/is_registered`,
      )
      if (res?.data?.success) {
        setButtonBehaviour(res?.data?.data)
      } else {
        throw new Error(res?.data?.message)
      }
    } catch (err) {
      console.log(err || 'Something went wrong. Please try after sometime')
    }
  }

  const setButtonBehaviour = ({ qualifiedSlot, stage }: any) => {
    if (qualifiedSlot && stage && isLoggedIn) {
      if (
        counsellingStageRank[stage] < 3 &&
        (qualifiedSlot == '1' || qualifiedSlot == '2') && !showOnlyPaymentNudge
      ) {
        setButtonFlow('counsellingNudge')
      }
      if (
        (counsellingStageRank[stage] == 7 ||
          counsellingStageRank[stage] == 8) &&
        (qualifiedSlot == '1' || qualifiedSlot == '2')
      ) {
        setButtonFlow('paymentNudge')
      }
    }
  }

  if (!buttonFlow) {
    return null
  } else {
    return (
      <div
        className="fixed shadow-2 rounded-100"
        style={{
          bottom: isMobileSized ? '30px' : '50px',
          right: isMobileSized ? '15px' : '50px',
          zIndex: 1000,
        }}
      >
        <StyledButton
          ctaText={
            buttonFlow == 'paymentNudge'
              ? 'Get LS Plus now'
              : 'Book free counselling'
          }
          onClick={() => {
            if (buttonFlow == 'paymentNudge') {
              trackClick(addedPageName, {
                widgetName: 'Sticky Button',
                widgetFormat: 'Nudge',
                contentName: 'Get LS Plus now',
                contentFormat: 'Button',
              })
              router.push(paymentPageUrl || navRoutes.SPOT_COUNSELLING_PAYMENT)
            }
            if (buttonFlow == 'counsellingNudge') {
              trackClick(addedPageName, {
                widgetName: 'Sticky Button',
                widgetFormat: 'Nudge',
                contentName: 'Book free counselling',
                contentFormat: 'Button',
              })
              router.push(navRoutes.SPOT_COUNSELLING)
            }
          }}
          className="px-4 py-3"
          bRadius="100px"
        />
      </div>
    )
  }
}

export default withWindowDimensions(OnPageTofuNudgeButton)
