import { getTokenCookie } from 'lib/auth-cookies-new'
import { AuthToken } from 'lib/auth_token'
import { NextPage, NextPageContext } from 'next'
import { Component } from 'react'
import { authActions } from './redux/AuthActions'

export type AuthProps = {
  auth?: AuthToken
}

export default function withAuth<P extends AuthProps>(
  WrappedComponent: NextPage<P>,
  shouldRedirect = true,
) {
  return class extends Component<P> {
    static async getInitialProps(ctx: NextPageContext) {
      const { store } = ctx
      const callbackPath = ctx.req?.url
      let auth_page = null

      const url = new URL('https://test.com' + callbackPath)
      auth_page = url.searchParams.get('auth_page')
      const utmSource = url.searchParams.get('utm_source') || undefined
      const utmCampaign = url.searchParams.get('utm_campaign') || undefined
      const utmTerm = url.searchParams.get('utm_term') || undefined

      const token = getTokenCookie(ctx)
      const auth = new AuthToken(token)
      const initialProps = { auth }
      store.dispatch(authActions.saveAuth(initialProps?.auth?.token))
      if (auth && auth.isExpired) {
        if (shouldRedirect) {
          let pageName = 'login'
          if (auth_page && auth_page !== '') {
            pageName = auth_page
          }
          ctx.res?.writeHead(302, {
            Location: this.buildAuthUrl(
              pageName,
              callbackPath,
              utmSource,
              utmCampaign,
              utmTerm,
            ),
          })
          ctx.res?.end()
        }
      }
      if (WrappedComponent.getInitialProps) {
        const componentProps = await WrappedComponent.getInitialProps(ctx)
        return {
          ...componentProps,
          ...initialProps,
        }
      }
      return initialProps
    }

    static buildAuthUrl(
      authPage: string,
      callbackPath?: string,
      utmSource?: string,
      utmCampaign?: string,
      utmTerm?: string,
    ): string {
      let finalUrl = `/${authPage}?redirected=true&callbackUrl=${encodeURIComponent(
        callbackPath || '',
      )}`
      if (utmSource) finalUrl += `&utm_source=${utmSource}`
      if (utmCampaign) finalUrl += `&utm_campaign=${utmCampaign}`
      if (utmTerm) finalUrl += `&utm_term=${utmTerm}`
      return finalUrl
    }

    get auth() {
      return new AuthToken(this.props.auth?.token)
    }

    render() {
      return <WrappedComponent {...this.props} auth={this.auth} />
    }
  }
}
